<template>
  <div class="login-page">
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <div class="col-xl-4 offset-xl-4 col-lg-6 offset-lg-3 col-md-8 offset-md-2">
            <div class="login__header">
              <div class="login__header--logo">
                <a href="/">
                  <img src="../assets/img/header-logo.png" alt="" />
                </a>
              </div>
              <!-- <div class="login__header--lang">
                                    <div class="header__leng">
                                        <ul>
                                            <li class="header__lang--item "><a href="#">kz</a></li>
                                            <li class="header__lang--item header__lang--active"><a href="#">ru</a></li>
                                            <li class="header__lang--item "><a href="#">en</a></li>
                                        </ul>
                                    </div>

                                </div> -->

              <div class="login__form">
                <form @submit.prevent="sign" v-if="ecpSign == false">
                  <div class="form__block--form">
                    <div class="form__block--subtitle">Вход</div>

                    <div class="login__tab">
                      <div class="row">
                        <div class="col-lg-6">
                          <p
                            class="toggle__btn"
                            @click="ecpSign = true"
                            v-bind:class="{ toggle__active: ecpSign == true }"
                          >
                            ЭЦП
                          </p>
                        </div>
                        <div class="col-lg-6">
                          <p
                            class="toggle__btn"
                            @click="ecpSign = false"
                            v-bind:class="{ toggle__active: ecpSign == false }"
                          >
                            Логин
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form__login--line">
                          <div class="form__login--input">
                            <label>
                              <div class="label__login--icon">
                                <img src="../assets/img/mail_login-icon.svg" />
                              </div>
                              <input
                                type="text"
                                class="input-linck"
                                required=""
                                v-model="email"
                                name="email"
                                value=""
                                placeholder="Электронная почта"
                              />
                            </label>
                            <span class="input-required" v-if="emailError"
                              >Неверное имя пользователя или пароль!</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form__login--line">
                          <div class="form__login--input">
                            <label>
                              <div class="label__login--icon">
                                <img
                                  src="../assets/img/password_login-v-icon.svg"
                                  v-if="inputType"
                                  @click="inputType = false"
                                />
                                <img
                                  src="../assets/img/password_login-icon.svg"
                                  v-else
                                  @click="inputType = true"
                                />
                              </div>
                              <input
                                type="password"
                                v-if="!inputType"
                                class="input-linck"
                                v-model="password"
                                required=""
                                name="password"
                                value=""
                                placeholder="Пароль"
                              />
                              <input
                                type="text"
                                v-else
                                class="input-linck"
                                v-model="password"
                                required=""
                                name="password"
                                value=""
                                placeholder="Пароль"
                              />
                            </label>
                            <span class="input-required" v-if="passwordError"
                              >Неверное имя пользователя или пароль!</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form__login--input">
                          <button class="input__button" type="submit">Войти</button>
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <div
                          class="form__login--forgot"
                          @click="$router.push('/recovery/2')"
                        >
                          <a>Забыли свой пароль?</a>
                        </div>
                      </div>
                      <!-- <div class="col-lg-12"  @click="$router.push('/registration')">
                                                    <div class="form__login--registr">
                                                        <a >Регистрация</a>
                                                    </div>
                                                </div> -->
                    </div>
                  </div>
                </form>

                <form @submit.prevent="handleSend()" v-if="ecpSign == true">
                  <div class="form__block--form">
                    <div class="form__block--subtitle">Вход</div>

                    <div class="login__tab">
                      <div class="row">
                        <div class="col-lg-6">
                          <p
                            class="toggle__btn"
                            @click="ecpSign = true"
                            v-bind:class="{ toggle__active: ecpSign == true }"
                          >
                            ЭЦП
                          </p>
                        </div>
                        <div class="col-lg-6">
                          <p
                            class="toggle__btn"
                            @click="ecpSign = false"
                            v-bind:class="{ toggle__active: ecpSign == false }"
                          >
                            Логин
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form__login--input">
                          <button class="input__button">Выбрать сертификат</button>
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div
                          class="form__login--forgot"
                          @click="$router.push('/recovery/2')"
                        >
                          <a>Забыли свой пароль?</a>
                        </div>
                      </div>
                      <!-- <div class="col-lg-12"  @click="$router.push('/registration')">
                                                    <div class="form__login--registr">
                                                        <a >Регистрация</a>
                                                    </div>
                                                </div> -->
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <v-snackbar
                    :timeout="-1"
                    v-model="showToggle"
                    absolute
                    bottom
                    :color="messageColor"
                    outlined
                    center
                    >
                    {{message}}

                    <template v-slot:action="{ attrs }">
                        <v-icon
                         :color="messageColor"

                            v-bind="attrs"
                            @click="showToggle = false"
                        >
                        mdi-close
                        </v-icon>
                    </template>
                </v-snackbar> -->

    <toggle :message="message" :showToggle="showToggle" :color="messageColor" />
  </div>
</template>

<script>
import toggle from "../components/Toggle";
import { mapActions } from "vuex";
export default {
  components: {
    toggle,
  },
  data() {
    return {
      inputType: false,
      emailError: false,
      passwordError: false,
      messageColor: "",
      message: "",
      email: "",
      password: "",
      showError: false,
      ecpSign: true,
      showToggle: false,
      ready: false,
    };
  },
  methods: {
    callError() {
      this.showToggle = true;
      this.messageColor = "red darken-2";
    },
    callSuccess() {
      this.showToggle = true;
      this.messageColor = "green darken-3";
    },

    seePassword(type) {
      if (type == true) {
        this.inputType = "text";
      } else {
        this.inputType = "password";
      }
    },
    ...mapActions(["SIGN_IN_USER"]),
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    validateInput() {
      if (!this.email) {
        this.emailError = true;
        return false;
      }
      if (!this.password) {
        this.passwordError = true;
        return false;
      }
      if (this.validateEmail(this.email) == false) {
        this.emailError = true;
        return false;
      }
      return true;
    },
    sign() {
      if (this.validateInput() == false) {
        return false;
      }

      this.$axios({
        method: "post",
        url: this.$API_URL + this.$API_VERSION + "admin-login",
        data: {
          email: this.email,
          password: this.password,
        },
      })
        .then((response) => {
          localStorage.setItem("access_token_kcmr", response.data.data.token);
          let data_profile = {
            token: response.data.data.token,
            type: true,
            user: false,
          };
          this.SIGN_IN_USER(data_profile);
        })
        .catch((error) => {
          console.log(error.response);
          this.message = error.response.data.message;
          this.callError();
        });
    },
    handleSend() {
      if (this.ready == false) {
        this.message = "NCLayer не включен";
        this.callError();
      } else {
        this.showError = false;
        const data = {
          module: "kz.gov.pki.knca.commonUtils",
          method: "signXml",
          args: [
            "PKCS12",
            "AUTHENTICATION",
            "<login><sessionid>caacda70-fd36-45ed-8d94-45a88890f83a</sessionid></login>",
            "",
            "",
          ],
        };
        this.connection.send(JSON.stringify(data));
      }
    },
    sendEcp() {
      if (!this.esp__array.responseObject) {
        return false;
      }

      this.$axios({
        method: "post",
        url: this.$API_URL + this.$API_VERSION + "login-cms",
        data: {
          xmlCMS: this.esp__array.responseObject,
        },
      })
        .then((response) => {
          localStorage.setItem("access_token_kcmr", response.data.data.token);
          let data_profile = {
            token: response.data.data.token,
            type: true,
            user: false,
          };
          this.SIGN_IN_USER(data_profile);
        })
        .catch((error) => {
          this.showToggle = true;
          this.message = error.response.data.message;
          this.messageColor = "warning";

          console.warn(error);
        });
    },
  },
  created() {
    this.connection = new WebSocket("wss://127.0.0.1:13579/");
    this.connection.onopen = () => {
      this.ready = true;
    };

    this.connection.onmessage = (e) => {
      const arr = JSON.parse(e.data);
      const parse__arr = arr;
      this.esp__array = parse__arr;

      if (typeof this.esp__array !== "undefined") {
        this.sendEcp();
      }
    };
  },
  mounted() {},
  watch: {},
};
</script>
